html,
body {
  height: 100vh;
  margin: 0;
}
h3 {
  margin: 8px;
}
#root {
  height: 100%;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 8px;
}
